<template>
  <BCard>
    <h4 class="font-bold text-black text-2xl">
      Mutasi Bank Komerce
    </h4>
    <Card :bank-list-acc="optionsBankAcc" />
    <div class="d-flex justify-content-end flex-wrap gap-[10px]">
      <!-- Bank Acc -->
      <VSelect
        v-model="bankAccount"
        :reduce="e => e.id"
        :options="optionsBankAcc"
        label="text"
        :clearable="false"
        class="font-semibold !text-[#333] grow basis-[116px] col-12 col-lg-2 p-0"
        @input="getListMutation()"
      >
        <template #selected-option="data">
          <span
            v-if="!data.name"
            class="p-0 text-nowrap"
          >Bank Account</span>
          <span
            v-else
            class="p-0 text-nowrap"
          >{{ data.name }} <span v-if="data.account_number">-</span> {{ handleLableBank(data.account_number) }}</span>
        </template>
        <template #option="data">
          <span
            class="p-0 text-nowrap"
          >{{ data.name }} <span v-if="data.account_number">-</span> {{ data.account_number }}</span>
        </template>
      </VSelect>
      <!-- Date Range -->
      <date-range-picker
        ref="picker"
        v-model="dateRange"
        :locale-data="locale"
        :ranges="ranges"
        :opens="'left'"
        control-container-class="border-0"
        class="col-lg-2 col-12 p-0"
        @update="getListMutation()"
      >
        <template v-slot:input="picker">
          <div
            class="d-flex btn border rounded justify-content-between"
            style="border-color: #d8d6de !important"
          >
            <span>Tanggal Transaksi</span>
            <img
              src="https://storage.googleapis.com/komerce/assets/icons/arrow-down-light.svg"
              alt="arrow"
              class="ml-[5px]"
              width="10px"
            >
          </div>
          <span class="d-none">{{ picker }}</span>
        </template>
      </date-range-picker>
      <!-- Transaction Type -->
      <VSelect
        v-model="transactionType"
        :reduce="e => e.value"
        :options="typeTransactionList"
        label="text"
        :clearable="false"
        class="font-semibold !text-[#333] grow basis-[116px] col-12 col-lg-2 p-0"
        @input="getListMutation()"
      />
      <!-- search -->
      <b-input-group
        class="input-group-merge col-12 col-lg-3 p-0"
        size="md"
      >
        <b-input-group-prepend is-text>
          <feather-icon icon="SearchIcon" />
        </b-input-group-prepend>
        <b-form-input
          id="filterInput"
          v-model="search"
          :maxlength="32"
          :minlength="2"
          type="search"
          placeholder="Cari Keterangan / Nominal"
          debounce="500"
          @input="onChangeSearch"
        />
      </b-input-group>
      <BButton
        variant="outline-primary"
        @click="resetFilter()"
      >
        Reset
      </BButton>
    </div>
    <BOverlay
      :show="isLoading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <BTable
        ref="table-scroll"
        :items="mutationList"
        :fields="listMutation"
        show-empty
        empty-text="Tidak ada data yang ditampilkan."
        class="mt-1"
        hover
        sticky-header="500px"
        :fixed="windowWidth > 1000"
        :relative="windowWidth <= 1000"
        @scroll.native="handleScroll"
      >
        <template #cell(date)="data">
          <div>
            {{ formatDate(data.item.date) }}
          </div>
          <small class="text-secondary">{{ formatHours(data.item.date) }}</small>
        </template>
        <template #cell(username)="data">
          <div class="d-flex align-items-center flex-column flex-lg-row">
            <img
              v-if="data.item.icon"
              :src="data.item.icon"
              alt="bank"
              width="80px"
            >
            <div class="d-flex flex-column ml-2">
              <span>
                {{ data.item.username }}
              </span>
              <span class="font-bold">
                {{ data.item.account_number }}
              </span>
            </div>
          </div>
        </template>
        <template #cell(nominal)="data">
          <div class="font-semibold d-flex align-items-center">
            <img
              :src="data.item.transaction_type === 'CR' ? 'https://storage.googleapis.com/komerce/icon/credit-circle.svg':'https://storage.googleapis.com/komerce/icon/debit-circle.svg'"
              alt="credit"
              class="mr-[5px]"
              width="25px"
            >
            {{ IDR(data.item.nominal, 0, 0) }}
          </div>
        </template>
        <template #cell(description)="data">
          <div class="text-wrap">
            {{ handleDesc(data.item.description) }}
          </div>
        </template>
      </BTable>
    </BOverlay>
  </BCard>
</template>

<script>
import {
  LABELDAY, LABELMONTH, YEAR_MONTH_DAY, TIME,
} from '@/libs/filterDate'
import moment from 'moment'
import {
  today,
  last7,
  last2,
  last30,
  firstDateOfMonth,
  lastDateOfMonth,
  lastMonth,
  lastDateOfLastMonth,
} from '@/store/helpers'
import { IDR } from '@/libs/currency'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import VSelect from 'vue-select'
import '@core/scss/vue/libs/vue-select.scss'
import { newAxiosIns } from '../../../../libs/axios'
import { listMutation } from './config'
import Card from './Widget.vue'

export default {
  components: {
    Card,
    VSelect,
    DateRangePicker,
  },
  data() {
    return {
      IDR,
      YEAR_MONTH_DAY,
      TIME,
      locale: {
        format: 'dd/mm/yyyy',
        daysOfWeek: LABELDAY,
        monthNames: LABELMONTH,
      },
      ranges: {
        'Hari Ini': [today, today],
        '30 Hari Terakhir': [last30, today],
        'Bulan Ini': [firstDateOfMonth, today],
        'Bulan Kemarin': [lastMonth(1), lastDateOfLastMonth],
        'Custom Range': [today, today],
      },
      today,
      last7,
      last2,
      last30,
      firstDateOfMonth,
      lastDateOfMonth,
      dateRange: {
        startDate: last30,
        endDate: today,
      },
      search: '',
      transactionType: 'Tipe Transaksi',
      bankAccount: 'Bank Account',
      optionsBankAcc: [
        {
          id: '',
          name: 'Semua',
          account_number: '',
        },
      ],
      mutationList: [],
      typeTransactionList: [
        {
          text: 'Semua',
          value: '',
        },
        {
          text: 'Kredit',
          value: 'CR',
        },
        {
          text: 'Debit',
          value: 'DB',
        },
      ],
      listMutation,
      isLoading: false,
      windowWidth: window.innerWidth,
      page: 1,
      limit: 20,
      lastData: false,
    }
  },
  mounted() {
    this.getListMutation()
    this.getListBank()
    window.addEventListener('resize', this.updateWindowWidth)
  },
  methods: {
    handleDesc(v) {
      if (v.length > 90) {
        return `${v.slice(0, 90)}...`
      }
      return v
    },
    handleLableBank(value) {
      if (this.windowWidth > 1000) {
        if (value.length > 4) {
          return `${value.slice(0, 4)}...`
        }
      }
      return value
    },
    formatDate(d) {
      return `${moment(d)
        .format('MMM DD, YYYY')}`
    },
    formatHours(d) {
      return `${moment(d)
        .format('HH:mm:ss')} WIB`
    },
    onChangeSearch: _.debounce(function search() {
      const trimmedSearch = this.search.trim()
      this.search = trimmedSearch

      // eslint-disable-next-line no-unused-expressions, no-sequences
      this.bankAccount = 'Bank Account',
      this.startDate = YEAR_MONTH_DAY(this.dateRange.startDate),
      this.endDate = YEAR_MONTH_DAY(this.dateRange.endDate),
      this.transactionType = 'Tipe Transaksi'

      if (trimmedSearch.length > 2) {
        this.getListMutation()
      } else if (this.search === '') {
        this.getListMutation()
      }
    }, 1000),
    handleScroll(event) {
      const { scrollTop, scrollHeight, clientHeight } = event.target
      if (scrollTop + clientHeight >= scrollHeight - 1 && !this.loading
      && !this.lastData) {
        this.page += 1
        this.getListMutationNext()
      }
    },
    async getListMutation() {
      this.isLoading = true
      const params = {
        bank_account: this.bankAccount === 'Bank Account' ? '' : this.bankAccount,
        search: this.search,
        start_date: YEAR_MONTH_DAY(this.dateRange.startDate),
        end_date: YEAR_MONTH_DAY(this.dateRange.endDate),
        transaction_type: this.transactionType === 'Tipe Transaksi' ? '' : this.transactionType,
        limit: 20,
        page: 1,
      }

      const scrollContainer = this.$refs['table-scroll'].$el

      try {
        const response = await newAxiosIns.get('/moota/api/v1/bank-mutations', { params })

        const data = Array.isArray(response.data.data) ? response.data.data : []

        this.mutationList = data
        this.isLoading = false
        this.page = 1
        this.limit = 20
        if (scrollContainer) {
          scrollContainer.scrollTop = 0
        }

        if (data.length === this.limit) {
          this.lastData = false
        }
      } catch (err) {
        console.error('Error fetching data:', err)
        this.mutationList = []
      } finally {
        this.isLoading = false
      }
    },
    async getListMutationNext() {
      this.isLoading = true

      if (!this.lastData) {
        const params = {
          bank_account: this.bankAccount === 'Bank Account' ? '' : this.bankAccount,
          search: this.search,
          start_date: YEAR_MONTH_DAY(this.dateRange.startDate),
          end_date: YEAR_MONTH_DAY(this.dateRange.endDate),
          transaction_type: this.transactionType === 'Tipe Transaksi' ? '' : this.transactionType,
          limit: this.limit,
          page: this.page,
        }

        try {
          const response = await newAxiosIns.get('/moota/api/v1/bank-mutations', { params })

          const data = Array.isArray(response.data.data) ? response.data.data : []
          this.mutationList.push(...data)

          if (data.length < this.limit) {
            this.lastData = true
          }
        } catch (err) {
          console.error('Error fetching data:', err)
        } finally {
          this.isLoading = false
        }
      }
    },
    updateWindowWidth() {
      this.windowWidth = window.innerWidth
    },
    async getListBank() {
      await newAxiosIns
        .get('/moota/api/v1/bank/accounts')
        .then(res => {
          res.data.data.forEach(item => {
            this.optionsBankAcc.push(item)
          })
        })
        .catch(err => {
          this.$toast_error({ message: 'Gagal load list bank', timeout: 1000 })
          this.isLoading = false
        })
    },
    resetFilter() {
      // eslint-disable-next-line no-unused-expressions, no-sequences
      this.search = '',
      this.bankAccount = 'Bank Account',
      this.dateRange.startDate = last30,
      this.dateRange.endDate = today,
      this.transactionType = 'Tipe Transaksi',
      this.page = 1,
      this.limit = 20,
      this.getListMutation()
    },
  },
  // eslint-disable-next-line vue/order-in-components
  beforeDestroy() {
    // Remove event listener on destroy
    window.removeEventListener('resize', this.updateWindowWidth)
  },
}
</script>
