<template>
  <div class="mt-2 col-12 flex flex-column flex-lg-row gap-[10px]">
    <BCard class="border p-0 col-12 col-lg-5 bg-balance">
      <BOverlay
        :show="isLoadingMain"
        spinner-variant="primary"
        variant="light"
        blur="0"
        opacity="0"
        rounded="sm"
      >
        <div class="d-flex flex-column ">
          <div
            class="d-flex justify-content-between"
          >
            <span
              class="flex align-items-center text-[12px]"
            >
              <img
                src="https://storage.googleapis.com/komerce/icon/green-wallet.svg"
                alt="wallet"
                class="mr-[5px]"
                width="25px"
              >
              Saldo Bank Komerce</span>
            <VSelect
              v-model="bankAccount"
              :reduce="e => e.id"
              :options="bankListAcc"
              label="text"
              :clearable="false"
              class="!text-[#333] text-[12px] p-0 col-4"
              style="height: 38px;"
              @input="getMainBalance()"
            >
              <template #selected-option="data">
                <span
                  v-if="!data.name"
                  class="p-0 text-nowrap"
                >Semua</span>
                <span
                  v-else
                  class="p-0 text-nowrap"
                >{{ data.name }} <span v-if="data.account_number">-</span> {{ handleLableBank(data.account_number) }}</span>
              </template>
              <template #option="data">
                <span
                  class="p-0 text-nowrap"
                >{{ data.name }} <span v-if="data.account_number">-</span> {{ data.account_number }}</span>
              </template>
              <template #search="{ attributes, events }">
                <input
                  maxlength="1"
                  class="vs__search"
                  style="height: 0 !important;"
                  v-bind="attributes"
                  v-on="events"
                >
              </template>
            </VSelect>
          </div>
          <span class="text-[30px] text-black font-bold">{{ IDR(mainBalance) }}</span>
          <small class="mt-2">Saldo Terupdate pada : <span class="text-black"> {{ formatDateDays(dateUpdate) }}</span></small>
        </div>
      </BOverlay>
    </BCard>
    <BCard class="border p-0 col-12 col-lg-3 bg-balance-in">
      <BOverlay
        :show="isLoadingCredit"
        spinner-variant="primary"
        variant="light"
        blur="0"
        opacity="0"
        rounded="sm"
      >
        <div class="d-flex flex-column ">
          <div class="d-flex justify-content-between">
            <span class="d-flex align-items-center text-[12px]">
              <img
                src="https://storage.googleapis.com/komerce/icon/credit-blue.svg"
                alt="wallet"
                class="mr-[5px]"
                width="25px"
              >
              Saldo Masuk</span>
            <VSelect
              v-model="labelFilterCredit"
              :reduce="e => e.value"
              :options="filterCredit"
              label="text"
              :clearable="false"
              class="!text-[#333] text-[12px] p-0 col-5"
              style="height: 38px;"
              @input="getBalanceSummary('credit')"
            >
              <template #selected-option="data">
                <span
                  class="p-0 text-nowrap"
                >{{ handleLableCredit(data.text) }}</span>
              </template>
              <template #search="{ attributes, events }">
                <input
                  maxlength="1"
                  class="vs__search"
                  style="height: 0 !important;"
                  v-bind="attributes"
                  v-on="events"
                >
              </template>
            </VSelect>
          </div>
          <span class="text-[30px] text-black font-bold">{{ IDR(creditBalance) }}</span>
          <small class="mt-2">Dari {{ creditTransIn }} Transaksi</small>
        </div>
      </BOverlay>
    </BCard>
    <BCard class="border p-0 col-12 col-lg-3 bg-balance-out">
      <BOverlay
        :show="isLoadingDebit"
        spinner-variant="primary"
        variant="light"
        blur="0"
        opacity="0"
        rounded="sm"
      >
        <div class="d-flex flex-column ">
          <div class="d-flex justify-content-between">
            <span class="d-flex align-items-center text-[12px]">
              <img
                src="https://storage.googleapis.com/komerce/icon/debit-yellow.svg"
                alt="wallet"
                class="mr-[5px]"
                width="25px"
              > Saldo Keluar</span>
            <VSelect
              v-model="labelFilterDebit"
              :reduce="e => e.value"
              :options="filterDebit"
              label="text"
              :clearable="false"
              class="!text-[#333] text-[12px] p-0 col-5"
              style="height: 38px;"
              @input="getBalanceSummary('debit')"
            >
              <template #selected-option>
                <span class="p-0 text-nowrap">
                  {{ handleLableCredit(labelFilterDebit) }}
                </span>
              </template>
              <template #search="{ attributes, events }">
                <input
                  maxlength="1"
                  class="vs__search"
                  style="height: 0 !important;"
                  v-bind="attributes"
                  v-on="events"
                >
              </template>
            </VSelect>

          </div>
          <span class="text-[30px] text-black font-bold">{{ IDR(debitBalance) }}</span>
          <small class="mt-2">Dari {{ debitTransIn }} Transaksi</small>
        </div>
      </BOverlay>
    </BCard>
  </div>
</template>

<script>
import { IDR } from '@/libs/currency'
import {
  today,
  last7,
  last2,
  last30,
  firstDateOfMonth,
  lastDateOfMonth,
} from '@/store/helpers'
import VSelect from 'vue-select'
import '@core/scss/vue/libs/vue-select.scss'
import moment from 'moment'
import { newAxiosIns } from '@/libs/axios'

export default {
  components: {
    VSelect,
  },
  props: {
    bankListAcc: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      IDR,
      today,
      last7,
      last2,
      last30,
      firstDateOfMonth,
      lastDateOfMonth,
      bankAccount: 'Semua',
      selectedFilterCredit: [],
      labelFilterCredit: 'Hari Ini',
      filterCredit: [
        {
          text: 'Hari Ini',
          value: 'Hari Ini',
        },
        {
          text: 'Kemarin',
          value: 'Kemarin',
        },
        {
          text: '1 Minggu Lalu',
          value: '1 Minggu Lalu',
        },
        {
          text: '1 Bulan Lalu',
          value: '1 Bulan Lalu',
        },
      ],
      selectedFilterDebit: [],
      labelFilterDebit: 'Hari Ini',
      filterDebit: [
        {
          text: 'Hari Ini',
          value: 'Hari Ini',
        },
        {
          text: 'Kemarin',
          value: 'Kemarin',
        },
        {
          text: '1 Minggu Lalu',
          value: '1 Minggu Lalu',
        },
        {
          text: '1 Bulan Lalu',
          value: '1 Bulan Lalu',
        },
      ],
      creditBalance: 0,
      debitBalance: 0,
      mainBalance: 0,
      windowWidth: window.innerWidth,
      dateUpdate: '',
      creditTransIn: 0,
      debitTransIn: 0,
      isLoadingMain: false,
      isLoadingCredit: false,
      isLoadingDebit: false,
    }
  },
  mounted() {
    this.isLoadingMain = true
    this.isLoadingCredit = true
    this.isLoadingDebit = true
    this.getMainBalance()
    this.getBalanceSummary('credit')
    this.getBalanceSummary('debit')
    window.addEventListener('resize', this.updateWindowWidth)
  },
  beforeDestroy() {
    // Remove event listener on destroy
    window.removeEventListener('resize', this.updateWindowWidth)
  },
  methods: {
    formatDateParams(d) {
      return moment(d)
        .format('YYYY-MM-DD')
    },
    formatDateDays(d) {
      return `${moment(d)
        .format('dddd, DD MMM YYYY hh:mm')} WIB`
    },
    handleLableBank(value) {
      if (this.windowWidth > 1000) {
        if (value.length > 4) {
          return `${value.slice(0, 4)}...`
        }
      }
      return value
    },
    handleLableCredit(value) {
      if (this.windowWidth > 1000) {
        if (value.length > 8) {
          return `${value.slice(0, 8)}...`
        }
      }
      return value
    },
    async getBalanceSummary(v) {
      if (v === 'credit') {
        this.isLoadingCredit = true
      } else {
        this.isLoadingDebit = true
      }
      const dateMapping = {
        'Hari Ini': [today, today],
        Kemarin: [last2, last2],
        '1 Minggu Lalu': [last7, today],
        '1 Bulan Lalu': [last30, today],
      }

      const selectedFilter = v === 'credit' ? this.labelFilterCredit : this.labelFilterDebit
      const [startDate, endDate] = dateMapping[selectedFilter] || [today, today]

      const params = {
        bank_id: this.bankAccount === 'Semua' ? '' : this.bankAccount,
        type: v,
        start_date: this.formatDateParams(startDate),
        end_date: this.formatDateParams(endDate),
      }

      await newAxiosIns
        .get('/moota/api/v1/bank-mutations/summary', { params })
        .then(res => {
          if (v === 'credit') {
            this.isLoadingCredit = false
            this.creditBalance = res.data.data.amount
            this.creditTransIn = res.data.data.total_transaction
          } if (v === 'debit') {
            this.isLoadingDebit = false
            this.debitBalance = res.data.data.amount
            this.debitTransIn = res.data.data.total_transaction
          }
        })
        .catch(err => {
          this.$toast_error({ message: 'Gagal load Saldo bank', timeout: 1000 })
          this.isLoadingCredit = false
          this.isLoadingDebit = false
        })
    },
    async getMainBalance() {
      this.isLoadingMain = true
      const params = {
        bank_id: this.bankAccount === 'Bank Account' || this.bankAccount === 'Semua' ? '' : this.bankAccount,
      }
      await newAxiosIns
        .get('/moota/api/v1/bank-mutations/balance', { params })
        .then(res => {
          this.isLoadingMain = false
          this.mainBalance = res.data.data.balance
          this.dateUpdate = res.data.data.last_update
        })
        .catch(err => {
          this.$toast_error({ message: 'Gagal load Saldo bank', timeout: 1000 })
          this.isLoadingMain = false
        })
    },
    updateWindowWidth() {
      this.windowWidth = window.innerWidth
    },
  },
}
</script>

<style scoped>
.bg-balance {
  background-image: url('https://storage.googleapis.com/komerce/assets/bg-bank-balance.png');
  background-repeat: no-repeat;
  background-position: right bottom;
}
.bg-balance-in {
  background-image: url('https://storage.googleapis.com/komerce/assets/bg-balance-in.png');
  background-repeat: no-repeat;
  background-position: right bottom;
}
.bg-balance-out {
  background-image: url('https://storage.googleapis.com/komerce/assets/bg-balance-out.png');
  background-repeat: no-repeat;
  background-position: right bottom;
}
</style>
