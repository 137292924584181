/* eslint-disable import/prefer-default-export */
import { IDR } from '@/libs/currency'

export const listMutation = [
  {
    key: 'date',
    label: 'Tanggal',
    class: 'text-start font-[500]',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'align-top',
  },
  {
    key: 'username',
    label: 'Akun Bank',
    class: 'text-start font-[500]',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'align-top',
  },
  {
    key: 'nominal',
    label: 'Nominal',
    class: 'text-start font-[500]',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'align-top',
  },
  {
    key: 'description',
    label: 'Keterangan',
    class: 'text-start font-[500]',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'align-top',
  },
]
